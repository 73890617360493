<template>
  <b-card>
    <b-row>
      <h6 class="mx-1 mb-2 section-label">
        Activity
      </h6>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox
          id="accountSwitch1"
          v-model="noteSettingsData.company_update_emails"
          name="check-button"
          switch
          inline
        >
          <span>Public Digital News and Announcements Email</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox
          id="accountSwitch2"
          v-model="noteSettingsData.promotional_email"
          name="check-button"
          switch
          inline
        >
          <span>Promotional Emails</span>
        </b-form-checkbox>
      </b-col>

      <!-- application switch -->
      <h6 class="mx-1 mt-2 section-label">
        Application
      </h6>
      <b-col cols="12" class="mt-1 mb-2">
        <b-form-checkbox
          id="accountSwitch4"
          v-model="noteSettingsData.daily_case_track_update_email"
          name="check-button"
          switch
          inline
        >
          <span
            >Daily Tracked Cases Email Digest
            <b-badge variant="success"> Coming Soon!</b-badge>
          </span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox
          id="accountSwitch5"
          v-model="noteSettingsData.new_user_added_email"
          name="check-button"
          switch
          inline
        >
          <span>New User Added Email</span>
        </b-form-checkbox>
      </b-col>

      <!--/ application switch -->

      <!-- buttons -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-1 mr-1"
          @click="updateNotifications()"
        >
          Save changes
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          class="mt-1"
          variant="outline-secondary"
          @click="getNotifications()"
        >
          Cancel
        </b-button>
      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BFormCheckbox,
  BBadge
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BBadge
  },
  directives: {
    Ripple
  },
  props: {
    notificationData: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      noteSettingsData: {
        id: "",
        promotional_email: false,
        company_update_emails: false,
        daily_case_track_update_email: false,
        new_user_added_email: false
      },

      localOptions: JSON.parse(JSON.stringify(this.notificationData))
    }
  },
  created() {
    this.getNotifications("created")
  },
  methods: {
    getNotifications(created) {
      this.$AuthAxios.instance
        .get("/api-access/notifications/notification-settings/", {})
        .then((res) => {
          var self = this
          // eslint-disable-next-line no-unused-vars
          Object.keys(res.data).forEach(function(key, index) {
            self.noteSettingsData[key] = res.data[key]
          })

          if (created !== "created") {
            self.$emit("reset")
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "RESET!",
                icon: "CheckCircleIcon",
                text: "You have successfully reset!",
                variant: "success"
              }
            })
          }
        }) // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error!",
              icon: "XCircleIcon",
              text: "Something went wrong!",
              variant: "warning"
            }
          })
          console.log(error)
        })
    },
    updateNotifications() {
      this.$AuthAxios.instance
        .put("/api-access/notifications/notification-settings/", {
          data: this.noteSettingsData
        }) // eslint-disable-next-line no-unused-vars
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "SUCCESS!",
              icon: "CheckCircleIcon",
              text:
                "You have successfully updated your notification preferences!",
              variant: "success"
            }
          })
        }) // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error!",
              icon: "XCircleIcon",
              text: "Oh-no something went wrong!",
              variant: "warning"
            }
          })
          console.log(error)
        })
    }
  }
}
</script>
