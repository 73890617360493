<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="generalValues" tag="form">
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              vid="reauth"
              name="Current Password"
              rules="required"
            >
              <b-form-group
                label="Old Password"
                label-for="account-old-password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="Old Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                  >{{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="New Password"
              :rules="`required|is:${RetypePassword}`"
            >
              <b-form-group
                label-for="account-new-password"
                label="New Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="New Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback
                  :state="errors.length > 0 && validated ? false : null"
                >
                  {{
                    errors[0] === "New Password is not valid"
                      ? "Passwords do not match"
                      : errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Retype Password"
              :rules="`required|is:${newPasswordValue}`"
            >
              <b-form-group
                label-for="account-retype-new-password"
                label="Retype New Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="New Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback
                  :state="errors.length > 0 && validated ? false : null"
                >
                  {{
                    errors[0] === "Retype Password is not valid"
                      ? "Passwords do not match"
                      : errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="validate"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
              @click="$emit('reset')"
            >
              Reset
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BInputGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormGroup,
  BButton,
  BForm,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import { required } from "@validations"
import { extend } from "vee-validate"

import { ValidationProvider, ValidationObserver } from "vee-validate"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  components: {
    BInputGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  data() {
    return {
      required,
      validated: false,
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password"
    }
  },

  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon"
    }
  },
  created() {
    // eslint-disable-next-line no-unused-vars
    extend("is", (value) => {
      return this.newPasswordValue === this.RetypePassword
    })
  },
  methods: {
    reauthenticate() {
      // eslint-disable-next-line no-unused-vars
      const user = this.$firebase.auth().currentUser

      // TODO(you): prompt the user to re-provide their sign-in credentials
      // eslint-disable-next-line no-unused-vars
      const credential = this.passwordValueOld

      this.$firebase
        .auth()
        .signInWithEmailAndPassword(user.email, credential)
        // eslint-disable-next-line no-unused-vars
        .then((authUser) => {
          this.updatePassword()
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "XCircleIcon",
              text: `${error}`,
              variant: "danger"
            }
          })

          this.$refs.generalValues.setErrors({
            reauth: ["This is not your correct password."]
          })
        })

      //user
      //  .reauthenticateWithCredential(credential)
      //  .then(() => {
      //
      //    self.updatePassword()
      //    // User re-authenticated.
      //  }) // eslint-disable-next-line no-unused-vars
      //  .catch((error) => {
      //
      //    // An error ocurred
      //    // ...
      //  })
    },

    updatePassword() {
      const user = this.$firebase.auth().currentUser

      user
        .updatePassword(this.newPasswordValue)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "SUCCESS!",
              icon: "CheckCircleIcon",
              text: "Your Password Has Been Successfully Updated!",
              variant: "success"
            }
          })
          // Update successful.
        }) // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "XCircleIcon",
              text: `${error}`,
              variant: "danger"
            }
          })
          // An error ocurred
          // ...
        })
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password"
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password"
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password"
    },
    async validate() {
      var status = false
      var self = this
      await this.$refs.generalValues.validate().then(
        await function(success) {
          if (success) {
            status = true
            self.reauthenticate()
          } else {
            self.validated = true

            status = false
          }
        }
      )

      return status
    }
  }
}
</script>
