<template>
  <div><user-settings/></div>
</template>

<script>
import {} from "bootstrap-vue"
import UserSettings from "../../components/manage-settings/user-settings/UserSettings.vue"

export default {
  components: {UserSettings},
  data() {
    return {
      contentWidth: this.$store.state.appConfig.layout.contentWidth
    }
  },
  created() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed")
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", this.contentWidth)
  }
}
</script>
