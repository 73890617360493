<template>
  <b-tabs
    vertical
    content-class="mt-1 col-12 col-md-9 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">General</span>
      </template>

      <setting-general
        v-if="finishCall"
        :key="updateKey"
        :general-data="generalData"
        @reset="resetComponent"
        @updateUser="updateUserData"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <setting-password :key="updateKey" @reset="resetComponent" />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <!-- <b-tab>
    
      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Information</span>
      </template>

       <setting-information
        v-if="userData"
        :information-data="userData"
        @updateUser="updateUserData"
      /> 
    </b-tab> -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="BellIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Notifications</span>
      </template>

      <setting-notification
        v-if="userData"
        :key="updateKey"
        :notification-data="userData"
        @updateUser="updateUserData"
        @reset="resetComponent"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue"
import SettingGeneral from "./SettingGeneral.vue"
import SettingPassword from "./SettingPassword.vue"
//import SettingInformation from "./SettingInformation.vue"
import SettingNotification from "./SettingNotification.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
//import state from "@/store/notifications/moduleNoteState"
//import extensions from "@/router/routes/ui-elements/extensions"

export default {
  components: {
    BTabs,
    BTab,
    SettingGeneral,
    SettingPassword,
    //SettingInformation,
    SettingNotification
  },
  data() {
    return {
      finishCall: false,
      userData: true,
      updateKey: 0,
      // User DATA

      generalData: {
        appUserId: "",
        accountId: "",
        userId: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        phoneExtension: "",
        fax: "",
        faxExtension: "",
        office: "",
        officeExtension: "",
        permissionLevel: this.$store.getters["auth/role"],
        is_billing_contact: false,
        is_account_owner: false
      }

      /* THE DATA STRUCTURE FOR THE RESPONSE WILL BE AS FOLLOWS TO BEGIN WITH
         I WILL NEED TO ADD THE NOTIFICATION DATA
      userData: {
        user_id: "String",
        first_name: "String",
        last_name: "String",
        phone_number: "Number", // Fromat 5555555555
        phone_extension: "Number",
        email_address: "String",
        is_billing_contact: "Boolean",
        is_account_owner: "Boolean"
      }
       */
    }
  },
  created() {
    this.getUserData()
  },
  methods: {
    resetComponent() {
      this.updateKey += 1
    },
    getUserData() {
      this.$AuthAxios.instance
        .get("/api-access/user-data/details/")
        .then((res) => {
          this.generalData.appUserId = this.$store.getters["auth/id"]
          this.generalData.accountId = res.data.results.account_id
          this.generalData.userId = res.data.results.user_id
          this.generalData.firstName = res.data.results.first_name
          this.generalData.lastName = res.data.results.last_name
          this.generalData.email = res.data.results.email_address
            ? res.data.results.email_address
            : this.$store.getters["auth/email"]
          this.generalData.phone = res.data.results.phone_number
            ? res.data.results.phone_number
            : ""
          this.generalData.phoneExtension = res.data.results.phone_extension
            ? res.data.results.phone_extension
            : ""

          this.generalData.fax = res.data.results.fax_number
          this.generalData.faxExtension = res.data.results.fax_extension
          this.generalData.office = res.data.results.office_phone
          this.generalData.officeExtension = res.data.results.office_extension

          this.generalData.is_billing_contact =
            res.data.results.is_billing_contact
          this.generalData.is_account_owner = res.data.results.is_account_owner

          this.finishCall = true
        })
    },
    updateUserData(userDetails) {
      this.$AuthAxios.instance
        .put("/api-access/user-data/details/", userDetails) // eslint-disable-next-line no-unused-vars
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "CHANGES SAVED!",
              icon: "CheckCircleIcon",
              text: "You have successfully updated your General Information!",
              variant: "success"
            }
          })

          this.generalData = userDetails
        }) // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error!",
              icon: "XCircleIcon",
              text: "Something went wrong!",
              variant: "warning"
            }
          })
        })
    }
  }
}
</script>



